<template>
  <NuxtLink to="/" class="flex items-end gap-3" :class="{ 'gap-0': !showName }">
    <Logosvg alt="" :width="logoSize" class="max-w-full" v-if="sideBarExpanded" />
    <LogoSmallsvg alt="" :width="logoSize" class="max-w-full" v-else />
    <div v-if="showName" class="text-skin-heading text-lg font-medium uppercase leading-[0.8em]">
      Briik
    </div>
  </NuxtLink>
</template>

<script setup>
import Logosvg from '~/assets/svg/LOGOTYPE_BRIIK.svg';
import LogoSmallsvg from '~/assets/svg/LOGO-SMALL.svg';
import { useAppStore } from '~/stores/app';
import { storeToRefs } from 'pinia';

const appStore = useAppStore();

const { sideBarExpanded } = storeToRefs(appStore);

const props = defineProps({
  showName: {
    type: Boolean,
    default: false,
  },
  logoSize: {
    type: String,
    default: '32',
  },
});
</script>
